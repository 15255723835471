.mat-option {
  --default-bg: white;
  background-color: var(--default-bg);
}

.mat-option.mat-option-disabled:not(.placeholder) {
  background-color: rgb(0 0 0 / 10%) !important;
}

.mat-option.mat-option-disabled .mat-option-text {
  color: var(--faint-dark-color) !important;
}

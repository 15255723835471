button,
a.button {
  border: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  transition: 300ms ease-in-out;
  color: inherit;
}

.primary mat-icon {
  color: inherit;
}

button.primary,
a.primary {
  padding: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
}

button.primary:disabled {
  background-color: var(--faint-color);
}

button.secondary:disabled {
  opacity: 0.7;
}

button.primary:hover:not(:disabled),
a.primary:hover:not(:disabled) {
  background-color: var(--primary-light-color);
}

button.primary:active:not(:disabled),
a.primary:active:not(:disabled) {
  background-color: var(--primary-dark-color);
}

button.success,
a.success {
  padding: 10px;

  border-radius: 999px;
  background-color: var(--success-color);
  color: var(--primary-contrast-color);
}

button.success:hover:not(:disabled),
a.success:hover:not(:disabled) {
  background-color: var(--success-light-color);
}

button.success:active:not(:disabled),
a.success:active:not(:disabled) {
  background-color: var(--success-dark-color);
}

button.secondary,
a.secondary {
  padding: 10px;

  background-color: var(--primary-contrast-color);
  color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color) inset;
  transition: all 100ms ease-in-out;
}

button.secondary:hover:not(:disabled),
a.secondary:hover:not(:disabled) {
  opacity: 0.8;
}

button.secondary:active:not(:disabled),
a.secondary:active:not(:disabled) {
  color: var(--primary-dark-color);
  border-color: var(--primary-dark-color);
}

button.secondary:disabled {
  color: var(--faint-color);
  border-color: var(--faint-color);
}

@media only screen and (max-width: 600px) {
  .member-portal button,
  .member-portal a.button {
    min-height: 45px;
  }
}

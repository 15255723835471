@import "../../../common/shared/theme/variables.scss";
@import "../../../common/shared/theme/elements/style.css";
@import "variables.scss";
@import "./angular-material-custom-theme.scss";
@import "./elements/style.css";
@import "utils.css";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~video.js/dist/video-js.css";
@import "~videojs-record/dist/css/videojs.record.css";

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow: hidden;

  // Resolves issue with auto-scrolling when radio element at the bottom of the page is selected.
  position: fixed;
  height: 100%;
  width: 100%;
}

html,
body {
  margin: 0;
  color: var(--primary-font-color);
  font-size: var(--font-size);
  font-family: var(--font-family);
  background-color: var(--background-color);
  height: 100%;
}

mat-icon.mat-icon {
  width: 1.8rem;
  height: 1.8rem;
  font-size: 1.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--heading-font-color);
  font-weight: 600;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

ul.dashed-items-list li + li {
  border-top: 1px dashed var(--faint-light-color);
}

@media print {
  html {
    position: static;
    height: auto;
  }
}

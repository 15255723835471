.stats-preview {
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  gap: 20px;
  padding: 20px 5px;
  background: var(--secondary-background-color);
  border-radius: 3px;
}

.stats-preview__header {
  color: var(--accent-color);
  text-transform: uppercase;
  font-size: 1.2rem;
}

.stats-preview__list {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
}

.stats-preview__item {
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  gap: 5px;
  padding: 0 15px;
  text-align: center;
}

.stats-preview__item + .stats-preview__item {
  border-left: 1px dashed var(--faint-light-color);
}

.stats-preview__item-value {
  font-size: 3rem;
  font-weight: bolder;
}

.stats-preview__item-value_highlighted {
  color: red;
}

.stats-preview__item-label {
  text-align: center;
}

/* Inputs */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input:not([type]),
select,
mat-select,
.zc-input {
  --dropdown-icon-size: 20px;

  padding: 15px;
  font-size: inherit;
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  background-color: var(--secondary-background-color);
  width: 100%;
  color: inherit;
}

mat-select:focus-visible {
  outline: auto;
}

mat-select {
  padding: 0;
}

mat-select .mat-select-trigger {
  padding: 15px;
}

select option {
  color: var(--primary-font-color);
}

input[type="search"] {
  padding: 10px;
  background-image: url(/assets/search-icon.svg);
  background-size: 1rem;
  background-position: 10px center;
  background-repeat: no-repeat;
  padding-left: calc(20px + 1rem);
  border-color: var(--faint-light-color);
}

input:disabled,
textarea:disabled,
select:disabled {
  opacity: 0.8;
  background-color: var(--faint-light-color);
}

::placeholder,
/* Assumed that all of the options are correct, and a placeholder option selected. */
select.ng-invalid,
.mat-chip-list-wrapper input.mat-input-element::placeholder {
  font-weight: bold;
  color: var(--faint-color);
}

select,
.zc-select {
  appearance: none;
  width: 100%;
  color: var(--primary-font-color);
  background-image: url("/assets/dropdown.svg");
  background-position: calc(100% - var(--dropdown-icon-size)) center;
  background-repeat: no-repeat;
}

select.zc-filter {
  font-size: inherit;
  font-weight: inherit;
  width: max-content;
  border: none;
  background: none;
}

.zc-select__clearable {
  background-image: none;
}

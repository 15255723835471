label {
  display: inline-block;
}

label > span {
  font-weight: 400;
  display: block;
  width: fit-content;
}

label > input,
label > select,
label > textarea,
label > span + input,
label > span + select,
label > span + textarea {
  width: 100%;
}
